"use client";

import { sendGTMEvent } from "@next/third-parties/google";
import { ArrowRight } from "lucide-react";
import Link from "next/link";

import { ExaminePlusLink } from "@/components/cta";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import SaleWrapper from "@/components/sale-wrapper";
import Trial from "@/components/trial";
import { Badge } from "@/components/ui/badge";
import { buttonVariants } from "@/components/ui/button";
import { percentOff, salesPrices, saleVariant, upsellPercentOff } from "@/lib/data/sales";
import { cn } from "@/lib/utils";

const ExamineAICTA = () => {
	return (
		<Link
			href="/examine-ai"
			className="group flex items-center gap-x-2 rounded-xl border border-gray-200 bg-gradient-to-r from-primary to-examine-green-400 p-4 text-white hover:to-examine-green-600 hover:shadow-sm lg:text-xl"
		>
			<span>
				<Badge variant="premium" size="sm" className="inline">
					New
				</Badge>{" "}
				<span className="inline">
					Meet{" "}
					<span className="link text-white underline group-hover:text-examine-purple-400">
						ExamineAI
					</span>
					! Our chatbot uses exclusively material already published on Examine to answer your
					questions. Everything we publish is still human-made from start to finish, but now it’s
					more accessible than ever.
				</span>
			</span>
		</Link>
	);
};

const FreeTrialCTA = () => {
	return (
		<FreeTrialWrapper>
			<div className="pt-4">
				<div className="inline-flex flex-col px-6 py-4 font-semibold text-primary lg:text-xl">
					<span>
						<span>
							Unlock all of Examine with a{" "}
							<Link
								href="/plus"
								className="link underline"
								onClick={() => {
									sendGTMEvent({ event: "homepage_hero_cta_click", label: "free 7 day trial" });
									sendGTMEvent({ event: "homepage_click", label: "free 7 day trial" });
								}}
							>
								free <Trial /> trial
								<ArrowRight size={20} className="inline" />{" "}
							</Link>{" "}
						</span>
						<br />
						<span className="font-light">or</span>
						<br />
						<span>
							Start with a{" "}
							<Link
								href="/plus"
								className="link underline"
								onClick={() => {
									sendGTMEvent({ event: "homepage_click", label: "free account" });
								}}
							>
								free account <ArrowRight size={20} className="inline" />
							</Link>
						</span>
					</span>
				</div>
			</div>
		</FreeTrialWrapper>
	);
};

const SalesCTA = () => {
	return (
		<div className="pt-8">
			<ExaminePlusLink
				className={cn(
					buttonVariants({ variant: "primary" }),
					"inline-flex flex-col rounded-xl px-6 py-4 lg:text-xl"
				)}
				trackDetails={{
					trackFor: "sale",
					label: `Examine+ unlocks everything on the site On sale for over ${percentOff} off! Start now`,
					location: "Below Search on Homepage ~ Free",
				}}
				onClick={() => {
					sendGTMEvent({
						event: "homepage_click",
						label: "Examine+ unlocks everything on the site",
					});
				}}
			>
				<span>
					<strong>Examine+</strong> unlocks ExamineAI and everything on the site.
					<br className="hidden sm:block" />
					<Badge variant="premium" size="sm" className="my-2 uppercase">
						On sale for over {percentOff} off!
					</Badge>
					<br /> Start now
					<ArrowRight size={24} className="inline" />
				</span>
			</ExaminePlusLink>
		</div>
	);
};

const PaidUserSalesCTA = ({ plan }: { plan: "Monthly" | "Yearly" }) => {
	const upsellLinkYearly = "/promotion/yearlyupgrade?loc=checkout/jplqpJ6";
	const upsellLinkLifetime = "/promotion/mlifetimeupgrade?loc=checkout/OEJ3plv";

	const CTA = {
		Monthly: {
			upsellLink: "/plus/",
			label: `Upgrade your membership and save up to ${upsellPercentOff}`,
		},
		Yearly: {
			upsellLink: upsellLinkLifetime,
			label: `Get Examine+ Lifetime for ${salesPrices.lifetime} (${upsellPercentOff} off)`,
		},
	};

	return (
		<div className="mt-5 space-y-4 bg-examine-purple-100 px-6 py-5 text-start text-gray-900">
			<div className="flex flex-col items-center justify-center gap-4 py-2 xl:flex-row">
				<h2 className="text-center font-semibold leading-snug">
					<span className="inline font-lora">
						Examine’s <span className="capitalize">{saleVariant}</span> sale is on!
					</span>
					<span className="inline-block">
						<ExaminePlusLink
							trackDetails={{
								trackFor: "sale",
								label: "Unlock savings",
								location: `Below Search on Homepage ~ ${plan} Paid`,
							}}
							onClick={() => {
								sendGTMEvent({
									event: "homepage_click",
									label: "Unlock savings",
								});
							}}
						>
							<Badge
								variant="cyan"
								className="ml-2 whitespace-nowrap rounded-full bg-examine-green-500 px-2 py-1 align-middle  font-sans text-xs font-semibold text-white hover:bg-primary sm:text-sm"
							>
								Unlock savings
							</Badge>
						</ExaminePlusLink>
					</span>
				</h2>
			</div>
			<div className="space-y-2 sm:mx-4">
				<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
					Thank you for being a member! We hope you’re enjoying the benefits of your Examine+
					membership, including these new features designed to help you stay on top of the latest
					research:
				</p>

				<ul className="mx-auto list-outside list-disc space-y-1.5 pb-4 pl-6 text-sm sm:pl-8 lg:text-base xl:text-lg xl:leading-8">
					<li>
						ExamineAI: Easily search Examine’s archives of over 25 million words in seconds to get
						unbiased, easy-to-read answers to all of your health questions.
					</li>
					<li>Email alerts: Get detailed notifications for all new content on your saved pages.</li>
					<li>
						Personalized collections: Manage, collect, and organize pages important to you,
						including health topics, FAQs, guides, and summaries.
					</li>
					<li>Note taking: Add your private insights to any page for easy reference.</li>
				</ul>
				<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
					Stay tuned for more members-only features, perfect for enthusiasts, professionals, and
					everyone who needs straightforward answers to their health questions.
				</p>
				<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
					<strong>
						Until December 2, get a{" "}
						{plan === "Monthly" && (
							<>
								<Link href={upsellLinkYearly} className="link">
									Yearly membership for ${salesPrices.yearly}/year (${salesPrices.yearly / 12}
									/month)
								</Link>{" "}
								or{" "}
								<Link href={upsellLinkLifetime} className="link">
									lock in {upsellPercentOff} off Lifetime
								</Link>
							</>
						)}
						{plan === "Yearly" && (
							<>
								<Link href="/promotion/ylifetimeupgrade?loc=checkout/OEJ3plv" className="link">
									Lifetime membership for ${salesPrices.lifetime} ({upsellPercentOff} off)
								</Link>{" "}
								and get Examine+ access for life
							</>
						)}
					</strong>
					.{" "}
					<span className="relative inline-block before:absolute before:bottom-0.5 before:left-0 before:z-0 before:w-full before:bg-premium/40 before:pb-[2%] sm:before:pb-[3%]">
						<span className="relative whitespace-nowrap">
							You’ll get a prorated refund when
							<span className="hidden sm:inline"> you upgrade.</span>
						</span>
					</span>
					<span className="relative inline-block before:absolute before:bottom-0.5 before:left-0 before:z-0 before:w-full before:bg-premium/40 before:pb-[2%] sm:before:pb-[3%]">
						<span className="relative whitespace-nowrap">
							<span className="sm:hidden"> you upgrade.</span>
						</span>
					</span>
				</p>
				<div className="inline-flex w-full px-6 pt-4 text-center">
					<Link
						href={CTA[plan].upsellLink}
						className={cn(
							buttonVariants(),
							"mx-auto inline-flex font-semibold lg:px-4 lg:py-2 lg:text-xl"
						)}
					>
						<span>
							{CTA[plan].label} <ArrowRight size={24} className="inline" />
						</span>
					</Link>
				</div>
			</div>
		</div>
	);
};

const ExaminePlusCTA = () => {
	return (
		<SaleWrapper
			monthlyPaidCta={
				<>
					<ExamineAICTA />
					<PaidUserSalesCTA plan="Monthly" />
				</>
			}
			yearlyPaidCta={
				<>
					<ExamineAICTA />
					<PaidUserSalesCTA plan="Yearly" />
				</>
			}
			cta={
				<>
					<ExamineAICTA />
					<FreeTrialCTA />
				</>
			}
		>
			<SalesCTA />
		</SaleWrapper>
	);
};

export { ExaminePlusCTA };
