"use client";

import { sendGTMEvent } from "@next/third-parties/google";
import { useKeenSlider } from "keen-slider/react";
import { ArrowRight } from "lucide-react";
import Link from "next/link";
import * as React from "react";

import { buttonVariants } from "@/components/ui/button";
import Update from "@/components/update";
import { cn } from "@/lib/utils";
import autoplayPlugin from "@/lib/utils/autoplay-plugin";

const UpdatesSlider = ({ studyUpdates, pageUpdates }) => {
	const data = [
		{ title: "Latest Page Updates", lists: pageUpdates?.data ? [...pageUpdates?.data] : [] },
		{ title: "Latest Study Summaries", lists: studyUpdates?.data ? [...studyUpdates?.data] : [] },
	];

	const [created, setCreated] = React.useState(false);
	const [currentSlide, setCurrentSlide] = React.useState(0);
	const [ref, slider] = useKeenSlider<HTMLDivElement>(
		{
			loop: true,
			breakpoints: {
				"(min-width: 640px)": {
					slides: {
						perView: 2,
						spacing: 15,
					},
				},
			},
			slides: {
				perView: 1,
			},
			slideChanged(slider) {
				setCurrentSlide(slider.track.details.rel);
			},
			created() {
				setCreated(true);
			},
		},
		[autoplayPlugin]
	);

	return (
		<div className="bg-white py-4">
			{/* heading */}
			<div className="px-6 text-center">
				<h2 className="font-lora font-medium text-gray-900 lg:text-2xl xl:text-4xl">
					Latest Examine Updates
				</h2>
			</div>
			<div className="relative mx-auto w-full max-w-6xl pt-8 md:pt-10">
				<div
					ref={ref}
					className={cn("keen-slider lg:flex lg:justify-evenly", created ? "visible" : "invisible")}
				>
					{data?.map((item: any, index) => {
						const isActive =
							slider.current?.options?.disabled ||
							slider.current?.track?.details?.slides?.[index]?.abs ===
								slider.current?.track?.details?.abs;

						return (
							<div className="keen-slider__slide space-y-1 p-6" key={index}>
								<p className="font-semibold leading-7 xl:text-xl">{item.title}</p>
								<section className="snap-x snap-mandatory pt-4">
									{item?.lists?.map((update: any, index: React.Key) => (
										<Update {...update} key={index} index={index} view="preview" />
									))}
								</section>
							</div>
						);
					})}
				</div>

				{created && slider.current ? (
					<div className="py-6Locked flex items-center justify-center space-x-1.5 lg:hidden ">
						{[...Array(slider.current.track.details.slides.length).keys()].map((idx) => (
							<button
								className={`block h-3 ${
									currentSlide === idx
										? "w-6 rounded-lg bg-primary transition-[width] duration-200"
										: "w-3 rounded-full bg-gray-400"
								}`}
								key={idx}
								onClick={() => slider.current?.moveToIdx(idx)}
								aria-label={`Slide ${idx + 1}`}
							></button>
						))}
					</div>
				) : null}
			</div>
			<div className="mx-auto inline-flex w-full p-6 text-center">
				<Link
					href="/updates/"
					className={cn(
						buttonVariants(),
						"mx-auto inline-flex font-semibold lg:px-4 lg:py-2 lg:text-xl"
					)}
					onClick={() => {
						sendGTMEvent({
							event: "homepage_click",
							label: "See all of the latest updates at Examine",
						});
					}}
				>
					<span>
						See all of the latest updates at Examine{" "}
						<ArrowRight size={16} className="inline lg:stroke-4" />
					</span>
				</Link>
			</div>
		</div>
	);
};

export default UpdatesSlider;
