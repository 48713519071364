"use client";

import Image from "next/image";
import Script from "next/script";
import React from "react";
import { HiPlayCircle } from "react-icons/hi2";

export function ExamineVimeo() {
	const [isClicked, setIsClicked] = React.useState(false);
	const videoEL = React.useRef(null);

	const src = `https://player.vimeo.com/video/740493084?h=236c54366a&badge=0&autopause=0&player_id=0&app_id=58479&muted=1&autoplay=1`;
	const thumbnail = `https://i.vimeocdn.com/video/1490043476-3d7c1a5e5f77da7fb6ef59f411e91fb9f011e71c5d700c1f2a63c33084d064c3-d?mw=3100&mh=1757&q=70`;

	const handleClick = () => {
		setIsClicked(true);
	};

	if (!isClicked) {
		return (
			<div className="mx-auto !mt-0 max-w-xl px-3 py-10 text-center md:py-25 lg:max-w-3xl xl:max-w-5xl">
				<div
					className="relative w-full cursor-pointer overflow-hidden rounded-xl pb-[57%] shadow-2xl"
					onClick={() => handleClick()}
				>
					<Image
						src={thumbnail}
						alt="Examine plus - HD 1080p 5.45.17 PM"
						fill={true}
						sizes="(max-width: 768px) 75vw, (max-width: 1200px) 50vw, 33vw"
						placeholder="blur"
						blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
					/>
					<HiPlayCircle fill="white" className="absolute inset-0 m-auto size-12 lg:size-16" />
				</div>
			</div>
		);
	}

	return (
		<div className="mx-auto max-w-xl px-3 py-10 text-center md:py-25 lg:max-w-3xl xl:max-w-5xl">
			<div className="relative w-full overflow-hidden rounded-xl pb-[57%] shadow-2xl">
				<iframe
					src={src}
					ref={videoEL}
					allowFullScreen
					className="top-vi0 absolute left-0 size-full bg-white"
					title="examine plus - HD 1080p 5.45.17 PM"
				/>
			</div>
			<Script src="https://player.vimeo.com/api/player.js" strategy="lazyOnload" />
		</div>
	);
}
