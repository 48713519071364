import type { KeenSliderInstance } from "keen-slider/react";

export default function autoplay(slider: KeenSliderInstance, INTERVAL: number = 2500) {
	let timeout: ReturnType<typeof setTimeout>;
	let mouseOver = false;

	function clearNextTimeout() {
		clearTimeout(timeout);
	}
	function nextTimeout() {
		clearTimeout(timeout);
		if (mouseOver || slider.options.disabled) return;
		timeout = setTimeout(() => {
			/* @ts-ignore */
			slider.moveToIdx(slider.track.details.rel + slider.options?.slides?.perView);
		}, INTERVAL);
	}
	slider.on("created", () => {
		slider.container.addEventListener("mouseover", () => {
			mouseOver = true;
			clearNextTimeout();
		});
		slider.container.addEventListener("mouseout", () => {
			mouseOver = false;
			nextTimeout();
		});
		nextTimeout();
	});
	slider.on("dragStarted", clearNextTimeout);
	slider.on("animationEnded", nextTimeout);
	slider.on("updated", nextTimeout);
}
