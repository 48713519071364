"use client";

import { useKeenSlider } from "keen-slider/react";
import Link from "next/link";
import { useMemo, useState } from "react";

import { home } from "@/lib/data/home";
import { cn } from "@/lib/utils";
import autoplayPlugin from "@/lib/utils/autoplay-plugin";

const BuiltForSlider = () => {
	const { builtFor } = home;

	const [created, setCreated] = useState(false);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [ref, slider] = useKeenSlider<HTMLDivElement>(
		{
			loop: true,
			breakpoints: {
				"(min-width: 640px)": {
					slides: {
						perView: 2,
						origin: "center",
						spacing: 10,
					},
				},
				"(min-width: 768px)": {
					slides: {
						perView: 2.3,
						origin: "center",
						spacing: 10,
					},
				},
				"(min-width: 1024px)": {
					disabled: true,
				},
			},
			slides: {
				perView: 1,
			},
			slideChanged(slider) {
				setCurrentSlide(slider.track.details.rel);
			},
			created() {
				setCreated(true);
			},
		},
		[autoplayPlugin]
	);

	const sliderComponent = useMemo(
		() => (
			<div
				ref={ref}
				className={cn(
					"keen-slider lg:mx-auto lg:flex lg:w-11/12 lg:justify-between xl:w-4/5",
					created ? "visible" : "invisible"
				)}
			>
				{builtFor.map((item, index) => {
					const isActive =
						slider.current?.options?.disabled ||
						slider.current?.track?.details?.slides?.[index]?.abs ===
							slider.current?.track?.details?.abs;

					return (
						<div
							className={cn("keen-slider__slide p-6", !isActive ? "opacity-50" : "")}
							key={index}
						>
							<div className="flex flex-col space-y-4">
								<p className="font-lora font-semibold xl:text-xl">
									{item.link ? (
										<Link href={item.link} className="underline">
											{item.title}
										</Link>
									) : (
										item.title
									)}
								</p>
								<span className="leading-7 text-gray-600 lg:mt-4 xl:text-lg">{item.text}</span>
							</div>
						</div>
					);
				})}
			</div>
		),
		[ref, created, slider, builtFor]
	);

	return (
		<div id="examine-built-for" className="home-section bg-white py-10">
			{/* heading */}
			<div className="px-6 text-center">
				<h2 className="font-lora font-medium text-gray-900 lg:text-2xl xl:text-4xl">
					Examine is built for everyone
				</h2>
			</div>

			<div className="relative w-full py-8 md:py-10 lg:py-20">
				{sliderComponent}
				{created && slider.current ? (
					<div className="flex items-center justify-center space-x-1.5 pt-12 lg:hidden ">
						{[...Array(slider.current.track.details.slides.length).keys()].map((idx) => (
							<button
								className={`block h-3 ${
									currentSlide === idx
										? "w-6 rounded-lg bg-primary transition-[width] duration-200"
										: "w-3 rounded-full bg-gray-400"
								}`}
								key={idx}
								onClick={() => slider.current?.moveToIdx(idx)}
								aria-label={`Slide ${idx + 1}`}
							/>
						))}
					</div>
				) : null}
			</div>

			<div className="flex flex-col gap-y-4 px-6 py-4 text-center md:py-8 lg:py-10">
				<h3 className="font-semibold lg:text-2xl">
					Examine is designed specifically for your needs
				</h3>
				<p className="mx-auto max-w-3xl text-gray-700 lg:text-xl">
					Health is an enormous field of study, but everyone is different. Ensure you’re only
					getting the information that’s relevant to you with our personalization options,
					customizable research feed, and information management tools like notes and collections.
					Turn Examine into your learning hub.
				</p>
			</div>
		</div>
	);
};

export default BuiltForSlider;
