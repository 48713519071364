"use client";

import type { KeenSliderInstance } from "keen-slider/react";
import { useKeenSlider } from "keen-slider/react";
import { ArrowLeftCircle, ArrowRightCircle, ChevronLeft, ChevronRight } from "lucide-react";
import Image from "next/image";
import { useState } from "react";

import { cn } from "@/lib/utils";
import autoplayPlugin from "@/lib/utils/autoplay-plugin";

import logo4 from "../public/logos/bbc.png";
import logo1 from "../public/logos/columbia-university.png";
import logo5 from "../public/logos/medpage.png";
import logo3 from "../public/logos/northwestern-university.png";
import logo2 from "../public/logos/ny-times.png";
import logo6 from "../public/logos/washington-post.png";

const brandLogos = [logo1, logo2, logo3, logo4, logo5, logo6];

const BrandsCarousel = () => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [created, setCreated] = useState(false);

	const [ref, slider] = useKeenSlider<HTMLDivElement>({
		slides: {
			perView: 3,
			spacing: 20,
		},
		breakpoints: {
			"(min-width: 768px)": {
				slides: {
					perView: 4,
					spacing: 20,
				},
			},
			"(min-width: 1024px)": {
				slides: {
					perView: 5,
					spacing: 10,
				},
			},
			"(min-width: 1280px)": {
				slides: {
					// perView: "auto",
					perView: 6,
					spacing: 15,
				},
			},
		},
		slideChanged(slider: KeenSliderInstance) {
			setCurrentSlide(slider.track.details.rel);
		},
		created() {
			setCreated(true);
		},
	});

	return (
		<div className="bg-white">
			<div className="relative mx-auto w-full overflow-hidden p-4 md:py-8 lg:max-w-6xl">
				<div
					ref={ref}
					className={cn(
						"keen-slider items-center justify-center",
						created ? "visible" : "invisible"
					)}
				>
					{brandLogos.map((path, index) => (
						<div
							key={index}
							className={cn(
								"keen-slider__slide flex h-8 max-h-[33px] max-w-56 items-center",
								index === 2 && "!min-w-28 !max-w-28",
								index === 3 && "!min-w-36 !max-w-36"
							)}
						>
							<Image
								className={cn(
									"mx-auto h-auto w-auto object-scale-down opacity-90 grayscale hover:opacity-100 hover:grayscale-0"
								)}
								placeholder="blur"
								fill={true}
								priority={index < 3}
								src={path}
								alt={`Logo ${index + 1}`}
							/>
						</div>
					))}
				</div>

				{created && slider.current ? (
					<>
						<button
							type="button"
							className={`${
								currentSlide === 0 ? "opacity-30" : ""
							} absolute top-1/2 flex -translate-y-1/2 items-center justify-center rounded-full lg:size-7 2xl:hidden  `}
							onClick={() => slider.current?.prev()}
							aria-label="Previous slide"
						>
							<ChevronLeft className="size-7 shrink-0 text-primary" />
						</button>

						<button
							type="button"
							className={cn(
								"absolute right-4 top-1/2 flex -translate-y-1/2 items-center justify-center rounded-full lg:size-7 2xl:hidden",
								/* prettier-ignore */
								/* @ts-ignore */
								currentSlide === slider.current?.track?.details?.slides?.length - slider.current?.options?.slides?.perView
                  ? 'opacity-30'
                  : ''
							)}
							onClick={() => slider.current?.next()}
							aria-label="Next slide"
						>
							<ChevronRight className="size-7 shrink-0 text-primary" />
						</button>
					</>
				) : null}
			</div>
		</div>
	);
};

const INTERVAL = 5000;
const brandLogosV2 = [
	"apple",
	"nike",
	"harvard",
	"stanford",
	"johns-hopkins",
	"microsoft",
	"google",
	"meta",
	"amazon",
	"lakers",
	"yankees",
	"cowboys",
	"49ers",
	"warriors",
	"new-york-times",
	"washington-post",
	"bbc",
	"mens-health",
	"cnn",
	"nih",
	"mayo-clinic",
	"cleveland-clinic",
	"healthline",
	"dietitians-of-canada",
	"nasm",
	"nsca",
	"acsm",
];

const BrandsCarouselV2 = () => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [ref, slider] = useKeenSlider(
		{
			loop: true,
			slides: {
				perView: 3,
				spacing: 20,
			},
			breakpoints: {
				"(min-width: 768px)": {
					disabled: true,
				},
			},
			slideChanged(slider) {
				setCurrentSlide(slider.track.details.rel);
			},
		},
		[(slider) => autoplayPlugin(slider, INTERVAL)]
	);

	return (
		<>
			<div className="space-y-4 bg-white">
				<h2 className="mx-auto max-w-lg pt-4 font-lora text-xl font-medium text-gray-900 xl:max-w-5xl xl:pt-12 xl:text-4xl">
					Over <span className="font-semibold">15,000</span> members rely on Examine+ for unbiased
					nutrition information, including at the following companies:
				</h2>

				<div className="relative mx-auto w-full overflow-hidden py-4 md:py-8 lg:max-w-6xl">
					<div
						ref={ref}
						className="keen-slider flex items-center md:flex-wrap md:justify-center md:gap-x-14 md:gap-y-5"
					>
						{brandLogosV2.map((brand, idx) => (
							<div key={idx} className="keen-slider__slide flex justify-center md:basis-[10%]">
								<Image
									className={`${(idx === 14 || idx === 15) && "w-[120px] md:pt-3"} ${
										(idx === 1 || idx === 18) && "scale-[.6] md:p-0"
									} ${
										(idx === 8 || idx === 20) && "pt-4"
									} opacity-90 grayscale hover:opacity-100 hover:grayscale-0`}
									key={idx}
									src={`/home/logos/${brand}.png`}
									alt={brand}
									width={100}
									height={56}
									quality={100}
								/>
							</div>
						))}
					</div>
					<div className="mt-2 flex justify-center gap-2">
						<button
							type="button"
							className="flex items-center justify-center rounded-full md:hidden lg:size-7"
							onClick={() =>
								/* @ts-ignore */
								slider.current?.moveToIdx(currentSlide - slider.current?.options?.slides?.perView)
							}
							aria-label="Previous slide"
						>
							<ArrowLeftCircle className="size-9 shrink-0 text-primary" />
						</button>

						<button
							type="button"
							className="flex items-center justify-center rounded-full md:hidden lg:h-7 lg:w-7"
							onClick={() =>
								/* @ts-ignore */
								slider.current?.moveToIdx(currentSlide + slider.current?.options?.slides?.perView)
							}
							aria-label="Next slide"
						>
							<ArrowRightCircle className="size-9 shrink-0 text-primary" />
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export { BrandsCarousel, BrandsCarouselV2 };
