"use client";

import { useRouter } from "next/navigation";
import React, { useState } from "react";

import { Spinner } from "@/components/icons/spinner";
import { Button } from "@/components/ui/button";
import { useUI } from "@/components/ui/context";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import subscribeToNewsletter from "@/lib/utils/subscribe-to-newsletter";

import { ClientOnly } from "./client-only";

interface ResearchSubscriptionProps {
	className?: string;
	isModal?: boolean;
	placement?: string;
}

const ResearchSubscription = ({
	className,
	isModal = false,
	placement = "generic",
}: ResearchSubscriptionProps) => {
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");
	const router = useRouter();
	const { closeModal } = useUI();

	const handleSubmit = async (e: React.SyntheticEvent) => {
		e.preventDefault();

		try {
			setLoading(true);
			const res = await subscribeToNewsletter({ email, placement, form_name: "generic" });
			if (res) {
				router.push(`/insiders/welcome/?email=${email}`);
				// TODO Track on GA4
				setEmail("");
				isModal && closeModal();
			}
		} catch (e: any) {
			toast({
				title: e.response?.data?.message || e?.response?.data?.data,
				variant: "error",
			});
		}

		setLoading(false);
	};

	return (
		<ClientOnly>
			<form
				className={cn(
					"relative mx-8 max-w-2xl rounded border border-gray-400 bg-gray-100 px-6 py-14 text-center sm:mx-auto sm:px-8 sm:py-10",
					className
				)}
				onSubmit={handleSubmit}
			>
				{isModal && (
					<div className="absolute right-8 top-6 cursor-pointer" onClick={() => closeModal()}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="size-6"
						>
							<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</div>
				)}
				<h2 className="text-center font-lora text-2xl font-bold text-gray-700 sm:text-40">
					Don&apos;t miss out on the latest research
				</h2>
				<div className="mt-6">
					<label htmlFor="" className="mt-2 px-4 text-gray-700 md:px-0 xl:text-lg">
						Become an Examine Insider for FREE to stay on top of the latest nutrition research,
						supplement myths, and more
					</label>
					<div className="mt-6 flex w-full flex-wrap gap-2 sm:flex-nowrap">
						<Input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="Your email address"
							className="w-full border border-gray-400 placeholder:text-black/80 sm:w-[70%]"
							required
						/>
						<Button
							variant="primary"
							type="submit"
							disabled={loading}
							className="w-full justify-center py-3 sm:w-[30%]"
						>
							{/* TODO: @mrassili: build bug */}
							{/* @ts-ignore  */}
							{loading ? <Spinner /> : "I'm ready to learn"}
						</Button>
					</div>
				</div>
			</form>
		</ClientOnly>
	);
};

export default ResearchSubscription;
